const getDate = (timestamp: string, dayTimestamp: string): {time: string, weekday: string} => {
    const ts = new Date(parseInt(timestamp) * 1000)
    const dateTS = new Date(ts.getFullYear(), ts.getMonth(), ts.getDate()).getTime() / 1000
    let timeString = ''
    let weekday = ''
    const weekdays = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
    if (dateTS === parseInt(dayTimestamp)) {
        timeString = `${('0' + ts.getHours()).slice(-2)}:${('0' + ts.getMinutes()).slice(-2)}`
    } else {
        // timeString = `${('0' + ts.getDate()).slice(-2)}.${('0' + (ts.getMonth() + 1)).slice(-2)}.${ts.getFullYear().toString().slice(-2)}`
        timeString =  `${('0' + ts.getHours()).slice(-2)}:${('0' + ts.getMinutes()).slice(-2)}`
        weekday = weekdays[ts.getDay()]
    }

    return {
        time: timeString,
        weekday: weekday
    }
}

export default getDate
