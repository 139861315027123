
import {computed, defineComponent, watch} from "vue";
import getDate from "@/composables/getDate";

export default defineComponent ({
  name: "Chat",
  props: {
    id: {
      type: String,
      required: true
    },
    initials: {
      type: String,
      required: false
    },
    profileImage: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    chatId: {
      type: String,
      required: true
    },
    chatIsBot: {
      type: Boolean,
      required: true
    },
    lastMessage: {
      type: String,
      required: false
    },
    timestamp: {
      type: String,
      required: false
    },
    dayTimestamp: {
      type: String,
      required: true
    }
  },
  setup(props, context) {

    const dateOfLastPost = (timestamp:string) => getDate(timestamp, props.dayTimestamp)

    const timestamp = computed(() => {
      return props.timestamp
    })

    watch(timestamp, () => {
      if (timestamp.value) {
        dateOfLastPost(timestamp.value)
      }
    })

    const enterChat = () => {
      console.log('enterChat')
      context.emit('openChat', props.chatId, props.chatIsBot, props.initials, props.name, props.color, props.profileImage)
    }

    return {dateOfLastPost, enterChat}
  }
})
