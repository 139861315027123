
import {defineComponent, onUpdated, ref, watch} from "vue";
import {useRoute} from "vue-router";
import firebase from "firebase/app";
import Contact from "@/components/modals/phone/Contact.vue";
import Contacts from '@/types/Contacts'
import Message from "@/types/Message";
import getContacts from "@/composables/getContacts.ts";
import getMessages from "@/composables/getMessages.ts";
import getDate from "@/composables/getDate";
import sentMessage from "@/composables/sentMessage";
import updateCollection from "@/composables/updateCollection";
import getAnswer from "@/composables/getAnswer";
import getUser from "@/composables/getUser";

type Timestamp = firebase.firestore.Timestamp;

export default defineComponent ({
  name: "Phone",
  components: { Contact },
  props: {
    playerName: {
      type: String,
      required: true
    },
    solutionChat: {
      type: String,
      required: true
    },
    solutionCode: {
      type: String,
      required: true
    },
    timestamp: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const regex = /(<([^>]+)>)/ig;
    const route = useRoute()
    const id = route.params.id
    const chatIdRef = ref<string>('')
    const chatIsBotRef = ref<boolean>(false)
    const contactsRef = ref<Contacts[] | null>(null)
    const messageRef = ref<string>('')
    const messagesRef = ref<Message[] | null>(null)
    const chatInitialsRef = ref<string>('')
    const participansRef = ref<Set<string> | null>(null)
    const chatNameRef = ref<string>('')
    const colorRef = ref<string>('')
    const imageRef = ref<string>('')
    const toggleChat = ref<boolean>(false)
    const messagesDiv = ref<HTMLElement | null>(null)
    const isWrittingDiv = ref<HTMLElement | null>(null)
    let lastPostTS: string
    const weekdays: string[] = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
    const { error: contactsError, documents: contacts, load: loadContacts } = getContacts(`/games/${id}/contacts/`)
    const { error: messagesError, documents: messages, load: loadMessages } = getMessages(`/games/${id}/chats/`)
    const { error: addDocError, addDoc } = sentMessage()
    const { error: updateError, updateDoc } = updateCollection(`/games/${id}/contacts/`)

    // for local testing
    const local = ref<boolean>(window.document.location.hostname === '192.168.178.32')

    console.log("playerName", props.playerName)

    loadContacts(props.playerName)

    watch(contacts, () => {
      let contactArr: Contacts[] = []
      let contactArrPastOnly: Contacts[] = []
      if (contacts.value && !contactsError.value) {
        // sort contacts according timestamp
        contactArr = contacts.value.sort((a, b) => (a.timestamp < b.timestamp) ? 1 : ((b.timestamp < a.timestamp) ? -1 : 0))
        // hide contacts with timestamp in the future
        const {user} = getUser();
        if (!user.value && !local.value) {
          contactArr.forEach((item, index) => {
            const ts = new Date(item.timestamp.seconds * 1000)
            const postTSLast = new Date(ts.getFullYear(), ts.getMonth(), ts.getDate()).getTime() / 1000
            if (postTSLast <= parseInt(props.timestamp)) {
              contactArrPastOnly.push(item)
            }
          })
          contactsRef.value = contactArrPastOnly
        } else {
          contactsRef.value = contactArr
        }
      }
    })

    const openChat = async (chatId:string, chatIsBot:boolean, chatInitials:string, chatName:string, chatColor:string, chatImage:string) => {
      lastPostTS = ''
      chatIdRef.value = chatId
      chatIsBotRef.value = chatIsBot
      chatInitialsRef.value = chatInitials
      chatNameRef.value = chatName
      colorRef.value = chatColor
      imageRef.value = chatImage
      loadMessages(chatId)
      await delay(300)
      toggleChat.value = !toggleChat.value
    }

    watch(messages, () => {
      if (messages.value && !messagesError.value) {
        messagesRef.value = messages.value.reverse()
        // let groupChatInitials = new Set()
        const arrInitials = messagesRef.value.map((item) => item.author)
        const setInitials = new Set(arrInitials);
        setInitials.delete(props.playerName)
        participansRef.value = (setInitials.size > 2) ? setInitials : null
        console.log("participansRef.value", participansRef.value)
        console.log("set", setInitials.size)
      }
    })

    const dateOfPost = (timestamp:string) => getDate(timestamp, props.timestamp)

    const dateComparison = (timestamp: string): string => {
      const ts = new Date(parseInt(timestamp) * 1000)
      let weekday = ''
      if (lastPostTS) {
        const lastTS = new Date(parseInt(lastPostTS) * 1000)
        const postTSLast = new Date(Date.UTC(lastTS.getFullYear(), lastTS.getMonth(), lastTS.getDate())).getTime()
        const postTS = new Date(Date.UTC(ts.getFullYear(), ts.getMonth(), ts.getDate())).getTime()
        if (postTS > postTSLast) {
          weekday = weekdays[ts.getDay()]
        }
      } else {
        weekday = weekdays[ts.getDay()]
      }
      lastPostTS = timestamp
      return weekday
    }


    const updateContact = (ts: Timestamp) => {
      const contactIdArr = chatIdRef.value.split('-')
      for (let i = 1; i <= contactIdArr.length; i++) {
        updateDoc(`${contactIdArr[i]}`, { lastMessage: messageRef.value, timestamp: ts })
        if (updateError) {
          console.log("Error: updateContact")
        }
      }
    }

    const handleNewMessage = async () => {
      console.log("messages.value", messages.value?.length)
      if (messages.value) {
        // console.log("messagesRef", messages.value[messages.value.length-1].orderId)
      }
      if (messageRef.value) {
        const now = new Date();
        const ts = new firebase.firestore.Timestamp(parseInt(props.timestamp) + (now.getHours() * 3600) + (now.getMinutes() * 60) + now.getSeconds(), 0)
        const newMessage = {
          chat: chatIdRef.value,
          author: props.playerName,
          text: messageRef.value.replace(regex,'') as string,
          timestamp: ts,
          orderId: (messages.value && messages.value?.length) ? messages.value[messages.value.length-1].orderId + 1 : 1
        }

        await addDoc(`/games/${id}/chats/`, newMessage)
        if (!addDocError.value) {
          messagesDiv.value?.classList.add('new-message')
          // TODO: Solution validation
          if (chatIdRef.value === "chat-" + props.solutionChat && messageRef.value === props.solutionCode ) {
            console.log("solutionChat", props.solutionChat)
            console.log("solutionCode", props.solutionCode)
          }
          await updateContact(ts)
          messageRef.value = ''
          if (chatIsBotRef.value) {
            handleNewAnswer()
          }
        }
      }
    }

    const handleNewAnswer = async () => {
      const answer: string = await getAnswer(messageRef.value, props.playerName)
      const contactId = chatIdRef.value.split('-')[1]

      await delay(6000)
      isWrittingDiv.value?.classList.add('active')
      await delay(2500)
      isWrittingDiv.value?.classList.remove('active')
      await delay(1000)
      isWrittingDiv.value?.classList.add('active')
      await delay(2500)
      isWrittingDiv.value?.classList.remove('active')

      const now = new Date();
      const ts = new firebase.firestore.Timestamp(parseInt(props.timestamp) + (now.getHours() * 3600) + (now.getMinutes() * 60) + (now.getSeconds()), 0)
      const answerMessage = {
        chat: chatIdRef.value,
        author: contactId,
        text: answer,
        timestamp: ts,
        orderId: (messages.value && messages.value?.length) ? messages.value[messages.value.length-1].orderId + 1 : 1
      }
      addDoc(`/games/${id}/chats/`, answerMessage)
      updateDoc(contactId, { lastMessage: answer.substring(0, 50) + ' ...', timestamp: ts as Timestamp })
    }

    onUpdated(() => {
      if (messagesDiv.value && messagesDiv.value) {
        messagesDiv.value.scrollTop = messagesDiv.value.scrollHeight
      }
    })

    const delay = async (duration: number) => {
      return new Promise<void>((resolve) => {
        setTimeout(() => resolve(), duration);
      });
    }

    return { toggleChat, contactsRef, openChat, messageRef, messagesRef, chatInitialsRef, chatNameRef, colorRef, imageRef, dateOfPost, dateComparison, handleNewMessage, messagesDiv, isWrittingDiv, participansRef }
  },
})
