import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'
import Contacts from "@/types/Contacts";

const updateCollection = (collection: string) => {

  const error = ref<string | null>(null)

  // add a new document
  const updateDoc = async (doc: string, obj: Partial<Contacts> ) => {
    error.value = null

    try {
      await projectFirestore.collection(collection).doc(doc).update(obj)
    }
    catch(err) {
      console.log(err.message)
      error.value = 'could not send the message'
    }
  }

  return { error, updateDoc }

}

export default updateCollection
