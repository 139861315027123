import { projectFirestore} from "@/firebase/config";
import {ref} from "vue";
import Message from "@/types/Message";

const sentMessage = () => {

    const error = ref<string | null>(null)

    const addDoc = async (collection:string, doc:Partial<Message>) => {
        error.value = null

        try {
            await projectFirestore.collection(collection).add(doc)
        } catch (err) {
            console.log("Error", err.message)
            error.value = 'Could not sent message: ' + err.message
        }
    }
    return { error, addDoc }
}

export default sentMessage
