import { ref, watchEffect } from 'vue'
import { projectFirestore } from '../firebase/config'
import Message from "@/types/Message";


const getMessages = (collection: string) => {

  const documents = ref<Message[] | null>(null)
  const error = ref<string | null>(null)

  const load = async (chatId: string) => {
    // register the firestore collection reference
    console.log("Load Messages")
    const collectionRef = await projectFirestore.collection(collection).where("chat", "==", chatId)
      .orderBy('orderId', 'desc')
      .limit(30)
    const unsub = collectionRef.onSnapshot((snap: { docs: any[]; }) => {
      const results: Message[] = []
      snap.docs.forEach(doc => {
        // must wait for the server to create the timestamp & send it back
        // doc.data().createdAt && results.push({...doc.data(), id: doc.id})
        results.push(<Message>{...doc.data()})
      });
      console.log("Results", results)
      // update values
      documents.value = results
      error.value = null
    }, (err: { message: any; }) => {
      console.log(err.message)
      documents.value = null
      error.value = 'could not fetch the data'
    })

    watchEffect((onInvalidate) => {
      console.log("unsub from Message")
      onInvalidate(() => unsub());
    });
  }

  return { error, documents, load }
}

export default getMessages
