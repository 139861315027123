const greeting = [
    "Hey, {{name}}! Bin gerade in Eile 🚒 melde mich",
    "Mensch {{name}} sehen wir uns mal wieder 🧐 oder chatten wir jetzt nur noch? 😉",
    "Ha 😃 hab grad an dich gedacht.",
    "Yo, was gibt's?",
    "Hi {{name}} 🥳 was gibt's Neues?",
    "sorry, {{name}} - bin gerade tierisch im Stress",
    "da schau her 👀 - dass du dich noch traust dich bei mir zu melden 😉",
    "Heeeeeeyy {{name}}!!!! hab n tierischen schädel 🤕 war gestern mit den jungs unterwegs. Paaartyyyy 🕺🍺🍺🍺🍺🤢🤮",
    "Hey {{name}}, schön von dir zu hören 🤗"
]

const answers = [
    "Ich verstehe die Frage nicht, {{name}}. Werde mal konkret",
    "Hmmmm ... da muss ich drüber nachdenken 🤔",
    "Weiß nicht was du meinst. Bin auch gerade unterwegs. See you!",
    "Können wir das später klären, {{name}}? Hab den Kleinen grad bei mir.",
    "ja was meinst du denn? Hä? 😜",
    "Was ist denn das für eine Frage? Ist alles gut mit dir, {{name}}?",
    "Ich weiß nicht 🤷 Interessiert mich auch nicht die Bohne 🙃",
    "Und das fragst du mich???? 😳"
    ]

const getAnswer = (msg:string, name: string): string => {
    const isQ =  (msg.slice(-1) === '?')
    let returnMsg = ''
    if (isQ) {
        returnMsg = answers[Math.floor(Math.random()*answers.length)].replace('{{name}}', capitalize(name))
    } else {
        returnMsg = greeting[Math.floor(Math.random()*greeting.length)].replace('{{name}}', capitalize(name))
    }
    return returnMsg
}

const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || ""

export default getAnswer
