import { ref, watchEffect } from 'vue'
import { projectFirestore } from '../firebase/config'
import Contacts from "@/types/Contacts";


const getContacts = (collection: string) => {

  const documents = ref<Contacts[] | null>(null)
  const error = ref<string | null>(null)

  const load = async (playerName:string) => {

    // register the firestore collection reference
    const collectionRef = await projectFirestore.collection(collection)
      .where('contactOf', 'array-contains', playerName)
      .orderBy('timestamp')
    const unsub = collectionRef.onSnapshot((snap: { docs: any[]; }) => {
      const results: Contacts[] = []
      snap.docs.forEach(doc => {
        // must wait for the server to create the timestamp & send it back
        // doc.data().createdAt && results.push({...doc.data(), id: doc.id})
        results.push(<Contacts>{...doc.data(), id: doc.id})
      });

      // update values
      documents.value = results
      error.value = null
    }, (err: { message: any; }) => {
      console.log(err.message)
      documents.value = null
      error.value = 'could not fetch the data'
    })

    watchEffect((onInvalidate) => {
      onInvalidate(() => unsub());
    });
  }

  return { error, documents, load }
}

export default getContacts
